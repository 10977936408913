@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    /* color: #1e1e1e; */
    font-style: normal;
    color: #adb2b1;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Thin.ttf') format('truetype');
    font-weight: 100; /* Font-weight 100 */
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200; /* Font-weight 200 */
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300; /* Font-weight 300 */
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400; /* Font-weight 400 */
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500; /* Font-weight 500 */
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600; /* Font-weight 600 */
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700; /* Font-weight 700 (bold) */
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800; /* Font-weight 800 (bold) */
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Black.ttf') format('truetype');
    font-weight: 900; /* Font-weight 800 (bold) */
}

.app {
    background-image: url(./assets/images/white-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100vw;
    background-color: #1a201d;
}
