/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        border-radius: 3px;
    }
    .swiper-pagination-bullet-active {
        width: 12px;
    }
}
@media only screen and (max-width: 900px) {
    .card-content {
        flex-direction: column-reverse;
    }
    .card-img {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        div:first-child {
            margin-right: 15px;
        }
    }
}
@media only screen and (max-width: 400px) {
    .card-img {
        display: block;
        div:first-child {
            margin-right: 15px;
        }
    }
}

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
// }

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
// }
