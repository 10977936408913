.container {
  // max-width: 1240px;
  width: 90%;
  margin: 0 auto;
  padding: 24px 0px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
}

.swiper-pagination-bullet-active {
  width: 48px;
  height: 8px;
  border-radius: 4px;
  background: #fff;
}

.input-form-register {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.1);
  // color: white;
  color: #adb2b1;
  padding: 8px 12px;
  width: 100%;
  outline: none;
  &:focus {
    border: 1px solid #fff;
  }

  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #adb2b1 !important;
  }

  ::placeholder {
    color: #adb2b1 !important;
  }
}
.input-form-modal {
  color: #1e1e1e;
  border-radius: 4px;
  border: 1px solid #adb2b1;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 12px;
  width: 100%;
  outline: none;
}

.MuiTypography-root {
  font-size: 14px;
  font-family: inherit !important;
  line-height: 24px !important;
}
.MuiBox-root {
  label,
  span {
    color: #1e1e1e;
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #1e1e1e !important;
  }

  ::placeholder {
    color: #1e1e1e !important;
  }
  path {
    color: #3cb371 !important;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .grid-item {
    font-size: 30px;
    text-align: center;
  }
  .grid-item:nth-child(3n - 1),
  .grid-item:nth-child(3n - 2) {
    border-right: 1px solid rgba(171, 171, 171, 0.2);
  }
  p {
    font-size: 15px;
    text-align: left;
  }
}

//icon menu
.icon-menu {
  display: inline-block;
  cursor: pointer;
  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: #3cb371;
    margin: 6px 0;
    transition: 0.4s;
  }

  .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }
}

.nav-mobile {
  flex-direction: column;
  z-index: 99;
  position: fixed;
  // background-color: #3cb371;
  background-color: #21402f;
  height: 100%;
  top: 0;
  left: -315px;
  width: 250px;
  justify-content: flex-start;
  transition: all 0.4s;

  align-items: flex-start;
  box-shadow: 1px 0px 8px 2px #21402f;

  li {
    width: 100%;
  }
  p {
    font-weight: 600;
    font-size: 16px;
    display: block;
    width: 100%;
    padding: 10px 20px;
    &:hover {
      background-color: white;
      color: #3cb371;
    }
  }
}

nav {
  a,
  p {
    cursor: pointer;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
}

.page-compare {
  li,
  p,
  h6.uppercase {
    color: #fff;
  }
}

.card-flip {
  width: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}
.is-flipped {
  transform: rotateY(180deg);
}
.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card__face--back {
  transform: rotateY(180deg);
}
